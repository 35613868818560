import React from "react";
import { Box, Typography } from "@mui/material";

const InfoNotice = ({
  textAlign = "center",
  padding = "10px",
  margin = "0",
  color = "#000000",
  backgroundColor = "#fffbdc",
  width = "100%",
  content = "info",
  fontWeight = "400",
  addContactUs = false,
  isError = false,  
}) => (
  <Box
    sx={{
      textAlign,
      padding,
      margin,
      color,
      backgroundColor : isError ? "#FF7E77" :  backgroundColor,
      borderRadius: "30px",
      width,
      display: "flex", // Flexbox pour centrer
      flexDirection: "column", // Gérer la disposition des enfants
      alignItems: "center", // Centrer horizontalement
      justifyContent: "center", // Centrer verticalement
      gap:1
    }}
  >
    <Typography
      variant="body1"
      sx={{
        margin:0,
        lineHeight: 1.5,
        fontSize: "95%",
        fontWeight : 500,
        color: "#000000",
        fontFamily: "Fira Sans, Helvetica, Arial, Lucida, sans-serif",
      }}
    >
      {content}
    </Typography>
    {addContactUs && (
            <Box
            sx={{
              width: { xs: "80%", sm: "30%" },
              height: { xs: "40px", sm: "40px" },
              backgroundColor: "#007ac2",
              border: "1px solid #e5e1e1",
              borderRadius: "50px",
              color: "white",
              textAlign: "center",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontSize: "clamp(12px, 2vw, 18px)", // Responsive text size
              "&:hover": {
                backgroundColor: "white",
                color: "#007ac2",
              },
            }}
          >
        <a
          href="https://mycover-up.fr/demander-votre-devis/"
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <Typography>Demander un devis</Typography>
        </a>    
        </Box>
    )}
  </Box>
);

export default InfoNotice;
