import React, { useState, useRef, useEffect } from "react";
import { Box, Typography, TextField, IconButton, Button } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import CloseIcon from "@mui/icons-material/Close";
import InfoNotice from "./InfoNotice";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

const CacheDimensionsForm = ({
  handleKeyDown,
  sizeX,
  sizeY,
  sizeZ,
  frontHeight,
  onSizeXChange,
  onRearYChange,
  onSizeZChange,
  dimensionsErrorString,
  dimensionsNeedDevis,
  isDimensionsError,
  P1,
  P2,
  H,
  L,
  installationType,
  bottom,
}) => {

  const theme = useTheme();
  const [view, setView] = useState("default");
  const [isChanged, setIsChanged] = useState(false);
  const [tempSizeX, setTempSizeX] = useState((sizeX / 10).toFixed(1)); // Valeur temporaire pour sizeX
  const [tempSizeY, setTempSizeY] = useState((sizeY / 10).toFixed(1)); // Valeur temporaire pour sizeY
  const [tempSizeZ, setTempSizeZ] = useState((sizeZ / 10).toFixed(1)); // Valeur temporaire pour sizeZ
  const [previousX, setPreviousX] = useState(sizeX)
  const [previousY, setPreviousY] = useState(sizeY)
  const [previousZ, setPreviousZ] = useState(sizeZ)
  const [tempFrontHeight, setTempFrontHeight] = useState(
    (frontHeight / 10).toFixed(1)
  ); // Valeur temporaire pour le frontHeight
  const isXs = useMediaQuery(theme.breakpoints.only("xs"));

  const handleClick = () => {
    // Change la vue à "default" seulement si elle n'est pas déjà en "default"
    if (view !== "default") {
      setView("default");
    }
  };

  const handleSizeXChange = (value) => {
    const numericValue = parseFloat(value.replace(",", ".")); // Remplacer la virgule par un point
    if (!isNaN(numericValue)) {
      onSizeXChange(numericValue * 10); // Conversion en mm
      setIsChanged(true);
    } else {
      console.error("La valeur entrée est invalide.");
    }
  };

  const handleSizeYChange = (value) => {
    const numericValue = parseFloat(value.replace(",", ".")); // Remplacer la virgule par un point
    if (!isNaN(numericValue)) {
      onRearYChange(numericValue * 10); // Conversion en mm
      setIsChanged(true);
    } else {
      console.error("La valeur entrée est invalide.");
    }
  };

  const handleSizeZChangeLocal = (value) => {
    const numericValue = parseFloat(value.replace(",", ".")); // Remplacer la virgule par un point
    if (!isNaN(numericValue)) {
      onSizeZChange(numericValue * 10); // Conversion en mm
      setIsChanged(true);
    } else {
      console.error("La valeur entrée est invalide.");
    }
  };

  const handleResetClick = () => {
    const sin2Degrees = Math.sin((2 * Math.PI) / 180);

    let Z;

    if (P1 + 80 >= P2 + 30) {
      Z = P1 + 80;
    } else if (P2 + 30 > P1 + 80) {
      Z = P2 + 30;
    }

    if(Z < 290){
      Z = 290;
    }

    let hFactor = 30;
    if(installationType == "facade-haut" && bottom){
      hFactor = 70;
    }else if(installationType == "facade-haut" || installationType == "facade-sol"){
      hFactor = 40;
    }

    let frontHeight = H + hFactor;

    if(frontHeight < 500 ) frontHeight = 500
    
    let rearHeight = Math.ceil(frontHeight + sin2Degrees * (Z - 15));

    let X = L + 90

    if(L < 310){
      X = 400
    }

    //setTempFrontHeight((frontHeight / 10).toFixed(1))

    handleSizeXChange(((X/10).toFixed(1)).toString().replace(".", ","));
    handleSizeZChangeLocal(((Z/10).toFixed(1)).toString().replace(".", ","));
    handleSizeYChange(((rearHeight/10).toFixed(1)).toString().replace(".", ","));

    setTempSizeX((X/10).toFixed(1));
    setTempSizeZ((Z/10).toFixed(1));
    setTempSizeY((rearHeight/10).toFixed(1));

    setPreviousX(X);
    setPreviousY(rearHeight);
    setPreviousZ(Z);
  };

  const handleBlurSizeX = () => {
    setTempSizeX((prev) => {
      const numericValue = parseFloat(prev.replace(",", "."));
      if (!isNaN(numericValue)) {
        if(numericValue * 10 == previousX) return prev;
        setPreviousX((numericValue * 10));
        if (numericValue !== sizeX / 10) {
          setIsChanged(true); // Déclencher l'état de changement uniquement si la valeur a changé
        }
        onSizeXChange(numericValue * 10); // Conversion en mm pour le backend
        return numericValue.toFixed(1); // Retourner la valeur formatée pour l'affichage
      }
      return (sizeX / 10).toFixed(1); // Revenir à la valeur initiale en cas d'erreur
    });
  };

  const handleBlurSizeY = () => {
    setTempSizeY((prev) => {
      const numericValue = parseFloat(prev.replace(",", "."));
      if (!isNaN(numericValue)) {
        if(numericValue * 10 == previousY) return prev;
        setPreviousY((numericValue * 10));
        if (numericValue !== sizeY / 10) {
          setIsChanged(true); // Déclencher l'état de changement uniquement si la valeur a changé
        }
        onRearYChange(numericValue * 10); // Conversion en mm pour le backend
        return numericValue.toFixed(1); // Retourner la valeur formatée pour l'affichage
      }
      return (sizeY / 10).toFixed(1); // Revenir à la valeur initiale en cas d'erreur
    });
  };

  const handleBlurSizeZ = () => {
    setTempSizeZ((prev) => {
      const numericValue = parseFloat(prev.replace(",", "."));
      if (!isNaN(numericValue)) {
        if(numericValue * 10 == previousZ) return prev;
        setPreviousZ((numericValue * 10));
        if (numericValue !== sizeZ / 10) {
          setIsChanged(true); 
        }
        onSizeZChange(numericValue * 10);
        return numericValue.toFixed(1); // Retourner la valeur formatée pour l'affichage
      }
      return (sizeZ / 10).toFixed(1); // Revenir à la valeur initiale en cas d'erreur
    });
  };

  useEffect(() => {
    setPreviousX(sizeX)
    setPreviousY(sizeY)
    setPreviousZ(sizeZ)
  },[sizeX, sizeY, sizeZ])

  const handleChangeSizeX = (e) => {
    let value = e.target.value;
    if (value.match(/[^0-9.,]/)) return; // Empêcher les caractères non numériques
    if (value.includes(",")) value = value.replace(",", "."); // Remplacer la virgule par un point
    setTempSizeX(value);
  };

  const handleChangeSizeY = (e) => {
    let value = e.target.value;
    if (value.match(/[^0-9.,]/)) return;
    if (value.includes(",")) value = value.replace(",", ".");
    setTempSizeY(value);
  };

  const handleChangeSizeZ = (e) => {
    let value = e.target.value;
    if (value.match(/[^0-9.,]/)) return;
    if (value.includes(",")) value = value.replace(",", ".");
    setTempSizeZ(value);
  };

  useEffect(() => {
    if (isNaN(frontHeight)) return;
    setTempFrontHeight((frontHeight / 10).toFixed(1));
  }, [frontHeight]);

  useEffect(() => {
    if (isNaN(sizeX)) return;
    setTempSizeX((sizeX / 10).toFixed(1));
  }, [sizeX]);

  useEffect(() => {
    if (isNaN(sizeY)) return;
    setTempSizeY((sizeY / 10).toFixed(1));
  }, [sizeY]);

  useEffect(() => {
    if (isNaN(sizeZ)) return;
    setTempSizeZ((sizeZ / 10).toFixed(1));
  }, [sizeZ]);

  const renderSideView = () => {
    return (
      <>
        <Box
          component="img"
          src="/types/dimensions-side.png" // Remplace par l'URL de l'image
          alt="Dimensions du cache"
          sx={{ width: "100%", borderRadius: 2 }}
        />
        <Typography
          variant="h3"
          sx={{
            fontSize: { xs: 12, sm: 12, md: 15 },
            fontWeight: "800",
            position: "absolute",
            top: "2%", // Adjust the position as needed
            left: "10px", // Adjust the position as needed
          }}
        >
          Vue de profil
        </Typography>
        {/* Close icon */}
        <IconButton
          sx={{
            position: "absolute",
            top: "10px", // Adjust the position as needed
            right: "10px", // Adjust the position as needed
            bgcolor: "black",
            color: "white",
            width: "24px",
            height: "24px",
            borderRadius: "50%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CloseIcon sx={{ fontSize: "16px" }} />
        </IconButton>

        {/* angle */}
        <Box
          sx={{
            position: "absolute",
            top: "10%",
            right: "39%",
            transform: "translate(50%, -50%)",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            bgcolor: "#2c8998",
            color: "white",
            width: "30px",
            height: "20px",
            borderRadius: "10px",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Typography variant="body2" sx={{ fontWeight: "bold" }}>
            {"2°"}
          </Typography>
        </Box>

        {/* jointure haute variable */}
        <Box
          sx={{
            position: "absolute",
            top: "14%",
            right: "18%",
            transform: "translate(50%, -50%)",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            bgcolor: "#2c8998",
            color: "white",
            width: "40px",
            height: "20px",
            borderRadius: "10px",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Typography variant="body2" sx={{ fontWeight: "bold" }}>
            {((Math.ceil(80 + sizeZ * Math.tan((2 * Math.PI) / 180))) /10).toFixed(1)}
          </Typography>
        </Box>

        {/* ecart mur */}
        <Box
          sx={{
            position: "absolute",
            top: "48%",
            right: "33%",
            transform: "translate(50%, -50%)",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            bgcolor: "#2c8998",
            color: "white",
            width: "30px",
            height: "20px",
            borderRadius: "10px",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Typography variant="body2" sx={{ fontWeight: "bold" }}>
            {8.5}
          </Typography>
        </Box>

        {/* hauteur variable */}
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            right: "18%",
            transform: "translate(50%, -50%)",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            bgcolor: "#2c8998",
            color: "white",
            width: "40px",
            height: "20px",
            borderRadius: "10px",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Typography variant="body2" sx={{ fontWeight: "bold" }}>
            {((sizeY - 2 * 80 - Math.ceil(sizeZ * Math.tan((2 * Math.PI) / 180))) /10).toFixed(1)}
          </Typography>
        </Box>

        {/* jointure bas */}
        <Box
          sx={{
            position: "absolute",
            bottom: "13%",
            right: "18%",
            transform: "translate(50%, -50%)",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            bgcolor: "#2c8998",
            color: "white",
            width: "30px",
            height: "20px",
            borderRadius: "10px",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Typography variant="body2" sx={{ fontWeight: "bold" }}>
            8.0
          </Typography>
        </Box>
      </>
    );
  };

  const renderTopView = () => {
    return (
      <>
        <Box
          component="img"
          src="/types/dimensions-top.png" // Remplace par l'URL de l'image
          alt="Dimensions du cache"
          sx={{ width: "100%", borderRadius: 2 }}
        />
        <Typography
          variant="h3"
          sx={{
            fontSize: { xs: 12, sm: 12, md: 15 },
            fontWeight: "800",
            position: "absolute",
            top: "2%", // Adjust the position as needed
            left: "10px", // Adjust the position as needed
          }}
        >
          Vue supérieure
        </Typography>

        {/* Close icon */}
        <IconButton
          sx={{
            position: "absolute",
            top: "10px", // Adjust the position as needed
            right: "10px", // Adjust the position as needed
            bgcolor: "black",
            color: "white",
            width: "24px",
            height: "24px",
            borderRadius: "50%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CloseIcon sx={{ fontSize: "16px" }} />
        </IconButton>

        {/* jointure 1 */}
        <Box
          sx={{
            position: "absolute",
            top: "14%",
            right: "24%",
            transform: "translate(50%, -50%)",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            bgcolor: "#2c8998",
            color: "white",
            width: "30px",
            height: "20px",
            borderRadius: "10px",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Typography variant="body2" sx={{ fontWeight: "bold" }}>
            8.0
          </Typography>
        </Box>

        {/* ecart mur */}
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            right: "37%",
            transform: "translate(50%, -50%)",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            bgcolor: "#2c8998",
            color: "white",
            width: "30px",
            height: "20px",
            borderRadius: "10px",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Typography variant="body2" sx={{ fontWeight: "bold" }}>
            {8.5}
          </Typography>
        </Box>

        {/* longueur variable */}
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            right: "24%",
            transform: "translate(50%, -50%)",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            bgcolor: "#2c8998",
            color: "white",
            width: "40px",
            height: "20px",
            borderRadius: "10px",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Typography variant="body2" sx={{ fontWeight: "bold" }}>
            {((sizeX - 80 * 2) /10).toFixed(1)}
          </Typography>
        </Box>

        {/* jointure 2 */}
        <Box
          sx={{
            position: "absolute",
            bottom: "9%",
            right: "24%",
            transform: "translate(50%, -50%)",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            bgcolor: "#2c8998",
            color: "white",
            width: "30px",
            height: "20px",
            borderRadius: "10px",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Typography variant="body2" sx={{ fontWeight: "bold" }}>
            8.0
          </Typography>
        </Box>
      </>
    );
  };

  const renderZoomView = () => {
    return (
      <>
        <Box
          component="img"
          src="/types/dimensions-zoom.png" // Remplace par l'URL de l'image
          alt="Dimensions du cache"
          sx={{ width: "100%", borderRadius: 2 }}
        />
        <Typography
          variant="h3"
          sx={{
            fontSize: { xs: 12, sm: 12, md: 15 },
            fontWeight: "800",
            position: "absolute",
            top: "2%", // Adjust the position as needed
            left: "10px", // Adjust the position as needed
          }}
        >
          Déport goutte d'eau anti-ruissellement
        </Typography>
        {/* Close icon */}
        <IconButton
          sx={{
            position: "absolute",
            top: "10px", // Adjust the position as needed
            right: "10px", // Adjust the position as needed
            bgcolor: "black",
            color: "white",
            width: "24px",
            height: "24px",
            borderRadius: "50%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CloseIcon sx={{ fontSize: "16px" }} />
        </IconButton>
      </>
    );
  };

  const renderDefaultView = () => {
    return (
      <>
        {/* Image représentant le cache */}
        <Box
          component="img"
          src="/types/default-dimensions.png" // Remplace par l'URL de ton image
          alt="Dimensions du cache"
          sx={{ width: "100%", borderRadius: 2 }}
        />

        {/* Largeur (sizeX) */}
        <Box
          sx={{
            position: "absolute",
            bottom: "22%",
            left: "36%",
            transform: "translate(-50%, -50%)",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            bgcolor: "white",
            height: "35px",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
            background: "#fff !important",
            width: "70px !important",
            borderColor: "#3397a6",
            borderRadius: "50px",
            borderWidth: 1, // Ajoutez une largeur de bordure explicite
            borderStyle: "solid", // Définir un style de bordure
            zIndex: selectedInfo === "Largeur" ? 5 : 1,
          }}
        >
          <TextField
            value={tempSizeX} // Utilisation de la valeur temporaire
            onChange={handleChangeSizeX} // Mise à jour de la valeur temporaire
            onBlur={handleBlurSizeX} // Mise à jour finale lors de la perte du focus
            variant="outlined"
            size="small"
            sx={{
              width: "90%",
              textAlign: "center",
              "& input": {
                textAlign: "center",
                fontWeight: "900",
                fontFamily:
                  "'Montserrat', Helvetica, Arial, Lucida, sans-serif",
                fontSize: "13px",
              },
              "& fieldset": { border: "none" },
            }}
          />
          <InfoIcon
            sx={{
              color: "#acacac",
              fontSize: "15px",
              position: "absolute",
              top: "29%",
              right: "3%",
              ml: 1,
              cursor: "pointer",
            }}
            onClick={(e) => {
              e.stopPropagation();
              handleInfoClick("Largeur");
            }}
          />
          {selectedInfo === "Largeur" && (
            <Box
              ref={popupRef}
              sx={{
                position: "absolute",
                top: isXs ? "35%" : "35%",
                right: isXs ? "-43.5vw" : "12%",
                mt: 1,
                width: isXs ? "80vw" : "400px",
                zIndex: 6,
                fontWeight: "300",
                padding: "20px",
                backgroundColor: "#f9f9f9",
                boxShadow: "0 0 35px rgb(0 0 0 / 19%)",
                borderRadius: "5px",
                fontFamily: "'Fira Sans',Helvetica,Arial,Lucida,sans-serif",
                fontSize: "13px",
              }}
            >
              <Typography variant="body2" color="textSecondary">
                Modifiez celle-ci si vous souhaitez l'adapter à l'environnement
                d'installation. Prévoyez au moins 10mm de jeu pour une
                installation entre murs (pour le passage des têtes de vis et
                pour éviter de rayer le cache lors de la pose).{" "}
              </Typography>
            </Box>
          )}
        </Box>

        {/* Hauteur avant */}
        <Box
          sx={{
            position: "absolute",
            top: "42%",
            left: "-10%",
            transform: "translate(50%, -50%)",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            bgcolor: "#2c8998",
            color: "white",
            borderRadius: "10px",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
            height: "35px",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
            width: "70px !important",
            borderColor: "#3397a6",
            borderRadius: "50px",
            borderWidth: 1,
            borderStyle: "solid",
            zIndex: selectedInfo === "Havv" ? 5 : 1,
          }}
        >
          <Typography
            variant="body2"
            sx={{
              textAlign: "center",
              fontWeight: "900",
              fontFamily: "'Montserrat', Helvetica, Arial, Lucida, sans-serif",
              fontSize: "13px",
            }}
          >
            {tempFrontHeight}
          </Typography>
          <InfoIcon
            sx={{
              fontSize: "15px",
              position: "absolute",
              top: "27%",
              right: "2%",
              color: "#ffffff",
              ml: 1,
              cursor: "pointer",
            }}
            onClick={(e) => {
              e.stopPropagation();
              handleInfoClick("Havv");
            }}
          />
          {selectedInfo === "Havv" && (
            <Box
              ref={popupRef}
              sx={{
                position: "absolute",
                top: "35%",
                right: isXs ? "-62vw" : "12%",
                mt: 1,
                width: isXs ? "75vw" : "400px",
                zIndex: 6,
                fontWeight: "300",
                padding: "20px",
                backgroundColor: "#f9f9f9",
                boxShadow: "0 0 35px rgb(0 0 0 / 19%)",
                borderRadius: "5px",
                fontFamily: "'Fira Sans',Helvetica,Arial,Lucida,sans-serif",
                fontSize: "13px",
              }}
            >
              <Typography variant="body2" color="textSecondary">
                La pente de 2° sur le dessus du cache, qui permet le bon
                écoulement de l'eau, implique une hauteur différente entre avant
                et arrière. La hauteur à l'avant résulte de la hauteur à
                l'arrière.
              </Typography>
            </Box>
          )}
        </Box>

        {/* Profondeur (sizeZ) */}
        <Box
          sx={{
            position: "absolute",
            bottom: "13%",
            right: "10%",
            transform: "translateX(-50%)",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            bgcolor: "white",
            height: "35px",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
            width: "70px !important",
            borderColor: "#3397a6",
            borderRadius: "50px",
            borderWidth: 1, // Ajoutez une largeur de bordure explicite
            borderStyle: "solid", // Définir un style de bordure
            zIndex: selectedInfo === "Prof" ? 5 : 1,
          }}
        >
          <TextField
            value={tempSizeZ} // Utilisation de la valeur temporaire
            onChange={handleChangeSizeZ} // Mise à jour de la valeur temporaire
            onBlur={handleBlurSizeZ}
            variant="outlined"
            size="small"
            sx={{
              width: "100%",
              textAlign: "center",
              "& input": {
                textAlign: "center",
                fontWeight: "900",
                fontFamily:
                  "'Montserrat', Helvetica, Arial, Lucida, sans-serif",
                fontSize: "13px",
              },
              "& fieldset": { border: "none" },
            }}
          />
          <InfoIcon
            sx={{
              color: "#acacac",
              fontSize: "15px",
              position: "absolute",
              top: "27%",
              right: "3%",
              ml: 1,
              cursor: "pointer",
            }}
            onClick={(e) => {
              e.stopPropagation();
              handleInfoClick("Prof");
            }}
          />
          {selectedInfo === "Prof" && (
            <Box
              ref={popupRef}
              sx={{
                position: "absolute",
                top: "35%",
                right: isXs ? "-13vw" : "12%",
                mt: 1,
                width: isXs ? "75vw" : "400px",
                zIndex: 6,
                fontWeight: "300",
                padding: "20px",
                backgroundColor: "#f9f9f9",
                boxShadow: "0 0 35px rgb(0 0 0 / 19%)",
                borderRadius: "5px",
                fontFamily: "'Fira Sans',Helvetica,Arial,Lucida,sans-serif",
                fontSize: "13px",
              }}
            >
              <Typography variant="body2" color="textSecondary">
                Modifiez celle-ci si vous souhaitez l'adapter à l'environnement
                d'installation. Attention, sa réduction risque de pénaliser le
                flux d'air.
              </Typography>
            </Box>
          )}
        </Box>

        {/* Hauteur arrière (sizeY)*/}
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            right: "10%",
            transform: "translate(50%, -50%)",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            bgcolor: "white",
            height: "35px",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
            width: "70px !important",
            borderColor: "#3397a6",
            borderRadius: "50px",
            borderWidth: 1, // Ajoutez une largeur de bordure explicite
            borderStyle: "solid", // Définir un style de bordure
            zIndex: selectedInfo === "Harr" ? 5 : 1,
          }}
        >
          <TextField
            value={tempSizeY} // Utilisation de la valeur temporaire
            onChange={handleChangeSizeY} // Mise à jour de la valeur temporaire
            onBlur={handleBlurSizeY}
            variant="outlined"
            size="small"
            sx={{
              width: "100%",
              textAlign: "center",
              "& input": {
                textAlign: "center",
                fontWeight: "900",
                fontFamily:
                  "'Montserrat', Helvetica, Arial, Lucida, sans-serif",
                fontSize: "13px",
              },
              "& fieldset": { border: "none" },
            }}
          />
          <InfoIcon
            sx={{
              fontSize: "15px",
              position: "absolute",
              top: "27%",
              right: "3%",
              ml: 1,
              color: "#acacac",
              cursor: "pointer",
            }}
            onClick={(e) => {
              e.stopPropagation();
              handleInfoClick("Harr");
            }}
          />
          {selectedInfo === "Harr" && (
            <Box
              ref={popupRef}
              sx={{
                position: "absolute",
                top: "100%",
                right: isXs ? "2.5vw" : "12%",
                mt: 1,
                width: isXs ? "80vw" : "400px",
                zIndex: 6,
                fontWeight: "300",
                padding: "20px",
                backgroundColor: "#f9f9f9",
                boxShadow: "0 0 35px rgb(0 0 0 / 19%)",
                borderRadius: "5px",
                fontFamily: "'Fira Sans',Helvetica,Arial,Lucida,sans-serif",
                fontSize: "13px",
              }}
            >
              <Typography variant="body2" color="textSecondary">
                La pente de 2° sur le dessus du cache, qui permet le bon
                écoulement de l'eau, implique une hauteur différente entre avant
                et arrière. Modifiez celle-ci si vous devez insérer le cache
                sous un rebord de fenêtre par exemple.{" "}
              </Typography>
            </Box>
          )}
        </Box>

        {/* Erreur */}
        {dimensionsErrorString && (
          <Box
            sx={{
              display: "block",
              position: "absolute",
              top: "0",
              width: "100%",
              padding: "15px",
              zIndex: 60,
            }}
          >
            <InfoNotice
              content={dimensionsErrorString}
              padding="10px 20px"
              addContactUs={dimensionsNeedDevis}
              fontWeight="900"
              isError={isDimensionsError}
            />
          </Box>
        )}

        {/* Infobulles flottantes */}
        <IconButton
          sx={{
            position: "absolute",
            top: "22%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            color: "#1976d2",
            zIndex: 4,
          }}
          onClick={() => setView("top")} // Changement de vue
        >
          <InfoIcon sx={{ fontSize: "18px", color: "#007ac3" }} />
        </IconButton>

        <IconButton
          sx={{
            position: "absolute",
            bottom: "49%",
            right: "25%",
            transform: "translate(50%, 50%)",
            color: "#1976d2",
            zIndex: 4,
          }}
          onClick={() => setView("side")} // Changement de vue
        >
          <InfoIcon sx={{ fontSize: "18px", color: "#007ac3" }} />
        </IconButton>

        {/* Nouvelle infobulle pour le zoom */}
        <IconButton
          sx={{
            position: "absolute",
            top: "38%",
            right: "45%",
            transform: "translate(50%, -50%)",
            color: "#1976d2",
            zIndex: 4,
          }}
          onClick={() => setView("zoom")} // Changement de vue vers zoom
        >
          <InfoIcon sx={{ fontSize: "18px", color: "#007ac3" }} />
        </IconButton>
      </>
    );
  };

  const infos = {
    Harr: "La pente de 2° sur le dessus du cache, qui permet le bon écoulement de l'eau, implique une hauteur différente entre avant et arrière. Modifiez celle-ci si vous devez insérer le cache sous un rebord de fenêtre par exemple.",
    Largeur:
      "Modifiez celle-ci si vous souhaitez l'adapter à l'environnement d'installation. Prévoyez au moins 10mm de jeu pour une installation entre murs (pour le passage des têtes de vis et pour éviter de rayer le cache lors de la pose).",
    Prof: "Modifiez celle-ci si vous souhaitez l'adapter à l'environnement d'installation. Attention, sa réduction risque de pénaliser le flux d'air.",
    Havv: "",
  };

  const handleInfoClick = (infoId) => {
    setSelectedInfo(selectedInfo === infoId ? null : infoId);
  };

  const [selectedInfo, setSelectedInfo] = useState(null);
  const popupRef = useRef(null);

  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setSelectedInfo(null);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <Box
      onClick={handleClick} // Ajout de l'événement onClick ici
      sx={{
        position: "relative",
        textAlign: "center",
        mt: 4,
        width: "100%",
        maxWidth: "400px", // Max width pour l'image
        mx: "auto", // Centrer horizontalement
        cursor: view === "default" ? "default" : "pointer",
      }}
    >
      {view === "default" && renderDefaultView()}
      {view === "side" && renderSideView()}
      {view === "top" && renderTopView()}
      {view === "zoom" && renderZoomView()}

      {/* Bouton pour reinitialiser */}
      {isChanged && (
        <Button
          variant="contained"
          onClick={handleResetClick}
          sx={{
            fontFamily: "'Fira Sans', Helvetica, Arial, Lucida, sans-serif",
            color: "#5CB1E2",
            boxShadow: "none !important",
            border: "1px solid transparent",
            fontSize: "12px",
            marginTop: { xs: "15px", md: 0 },
            borderRadius: 10,
            backgroundColor: "#efefef",
            "&:hover": {
              backgroundColor: "#efefef",
              border: "1px solid #5CB1E2",
            },
          }}
        >
          Réinitialiser les valeurs
        </Button>
      )}
    </Box>
  );
};

export default CacheDimensionsForm;
