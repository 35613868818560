import React, { useState, useRef, useEffect } from "react";
import InfoIcon from "@mui/icons-material/Info";
import {
  Box,
  Grid2,
  TextField,
  Typography,
  Tabs,
  Tab,
  styled,
  Snackbar,
  Button,
} from "@mui/material";
import CacheDimensionsForm from "./CacheDimensionsForm"; // Import du sous-composant
import InfoNotice from "./InfoNotice";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

const DimensionsForm = ({
  installationType,
  sizeL,
  sizeH,
  sizeP1,
  sizeP2,
  sizeX,
  sizeY,
  sizeZ,
  frontHeight,
  onSizeLChange,
  onSizeHChange,
  onSizeP1Change,
  onSizeP2Change,
  onSizeXChange,
  onRearYChange,
  onSizeZChange,
  dimensionsErrorString,
  dimensionsNeedDevis,
  setTabChange,
  bottom,
  isDimensionsError
}) => {
  const theme = useTheme();
  const [selectedTab, setSelectedTab] = useState(0);
  const [tempSizeL, setTempSizeL] = useState((sizeL / 10).toFixed(1));
  const [tempSizeH, setTempSizeH] = useState((sizeH / 10).toFixed(1));
  const [tempSizeP1, setTempSizeP1] = useState((sizeP1 / 10).toFixed(1));
  const [tempSizeP2, setTempSizeP2] = useState((sizeP2 / 10).toFixed(1));
  const [showSnackbar, setShowSnackbar] = useState(false); // state for Snackbar
  const [highlightFields, setHighlightFields] = useState(false);
  const isXs = useMediaQuery(theme.breakpoints.only("xs"));
  const isXsOrMd = useMediaQuery(theme.breakpoints.down("md")); // Détecter si on est sur mobile ou tablette
  const textFieldsRef = useRef([]); // Ref to hold the text field elements

  const FormTabStyled = styled(Tab)(({ theme, selected }) => ({
    [theme.breakpoints.up("xs")]: {
      maxWidth: "45%",
    },
    [theme.breakpoints.up("sm")]: {
      maxWidth: "60%",
    },
    [theme.breakpoints.up("md")]: {
      maxWidth: "50%",
    },
    [theme.breakpoints.up("xl")]: {
      maxWidth: "80%",
    },

    color: selected ? "#007ac3 !important" : "#007ac3 !important",
    transition: "background-color 0.3s, color 0.3s, box-shadow 0.3s !important",
    textTransform: "none !important",
    borderRadius: "8px 8px 0 0", // Coins arrondis en haut
    padding: "10px 20px",
    boxShadow: "none !important",
    fontWeight: "700",
    margin: "0 10px !important", // Espacement entre les onglets
    borderBottom: selected ? "2px solid #007ac3" : "2px solid transparent",

    "&:hover": {
      backgroundColor: !selected ? "#007ac3 !important" : "#007ac3 !important",
      color: !selected ? "#ffffff !important" : "#ffffff !important",
      boxShadow: "none !important",
      "& svg": {
        color: "#ffffff !important",
      },
    },
  }));

  const installationImages = {
    "facade-haut": {
      src: "/types/facade-dimensions.png",
      label: "Mesurer mon unité sur façade",
      subtitle1: "Mesurer mon ",
      subtitle2: "unité",
      subtitle3: " sur façade, sans marge supplémentaire",
    },
    "facade-sol": {
      src: "/types/facade-dimensions.png",
      label: "Mesurer mon unité proche du sol",
      subtitle1: "Mesurer mon ",
      subtitle2: "unité",
      subtitle3: " proche du sol, sans marge supplémentaire",
    },
    sol: {
      src: "/types/sol-dimensions.png",
      label: "Mesurer mon unité au sol",
      subtitle1: "Mesurer mon ",
      subtitle2: "unité",
      subtitle3: " au sol, sans marge supplémentaire",
    },
  };

  const selectAllText = (event) => {
    if (isXsOrMd) {
      event.target.select();
    }
  };

  const handleKeyDown = (event) => {
    if (
      !/[0-9]/.test(event.key) &&
      !["Backspace", "Tab", "ArrowLeft", "ArrowRight"].includes(event.key)
    ) {
      event.preventDefault();
    }
  };

  const infoMapping = {
    "facade-haut": { height: "1", width: "2", p1: "3", p2: "4" },
    "facade-sol": { height: "1", width: "2", p1: "3", p2: "4" },
    sol: { height: "1bis", width: "2", p1: "3bis", p2: "4bis" },
  };

  const infos = {
    1: "Hauteur mesurée du dessous des équerres au haut de l'unité",
    "1bis": "Hauteur mesurée du sol au haut de l'unité",
    2: "Largeur mesurée de l'unité « hors-tout », y compris les excroissances et tuyaux",
    3: "Profondeur mesurée du mur à l'avant de la grille du ventilateur de l'unité",
    "3bis":
      "Profondeur mesurée de l'arrière de l'unité à l'avant de la grille du ventilateur",
    4: "Profondeur mesurée du mur à l'avant des équerres",
    "4bis": "Profondeur mesurée du mur à l'avant des supports anti-vibration",
    desc: "",
    title: "",
  };

  const handleInfoClick = (infoId) => {
    setSelectedInfo(selectedInfo === infoId ? null : infoId);
  };

  const [selectedInfo, setSelectedInfo] = useState(null);
  const popupRef = useRef(null);

  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setSelectedInfo(null);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const renderTextFieldWithInfo = (label, value, onChange, onBlur, infoId, leftpadding) => (
    <Box sx={{ position: "relative" }}>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
        sx={{
          height: "100%",
          width: "100%",
        }}
      >
        <Typography
          variant="body1"
          gutterBottom
          sx={{
            color: "#007AC3",
            fontWeight: "bold",
            marginBottom: "0px",
            fontSize: { xs: "9px", sm: "9px", md: "12px", lg: "12px" },
            lineHeight: "normal",
          }}
        >
          {label} (cm)
        </Typography>
        <InfoIcon
          sx={{ fontSize: "18px", color: "#007ac3", ml: 1, cursor: "pointer" }}
          onClick={() => handleInfoClick(infoId)}
        />
      </Box>
      {selectedInfo === infoId && (
  <Box
    ref={popupRef}
    sx={{
      position: "absolute",
      top: isXs ? "0%" : "100%",
      left: isXs ? leftpadding : "50%",
      transform: "translateX(-50%)",
      mt: 1,
      width: { xs: "75vw", sm: "250px" },
      zIndex: 10,
      fontWeight: "300",
      padding: "20px",
      backgroundColor: "#f9f9f9",
      boxShadow: "0 0 35px rgb(0 0 0 / 19%)",
      borderRadius: "5px",
      fontFamily: "'Fira Sans',Helvetica,Arial,Lucida,sans-serif",
      fontSize: "13px",
    }}
  >
    <Typography variant="body2" color="textSecondary">
      {infos[infoId]}
    </Typography>
  </Box>
)}
      <TextField
        inputRef={(el) => (textFieldsRef.current[infoId] = el)} // Add to the refs array
        value={value}
        onChange={(e) => onChange(e.target.value)}
        onBlur={onBlur}
        type="tel"
        onFocus={selectAllText}
        onDragStart={(e) => e.preventDefault()}
        onSelect={(e) => e.preventDefault()}
        size="small"
        sx={{
          mt: 1,
          "& .MuiOutlinedInput-root": {
            borderRadius: "25px",
            "& fieldset": {
              borderColor: "#007AC3",
            },
            "&:hover fieldset": {
              borderColor: "#007AC3",
            },
            "&.Mui-focused fieldset": {
              borderColor: "#007AC3",
            },
            
            ...(highlightFields && {
              animation: "highlight 1s ease-out", // animation on highlight
            }),
          },
        }}
      />
    </Box>
  );

  const highlightFieldsAnimation = {
    "@keyframes highlight": {
      "0%": {
        backgroundColor: "yellow",
      },
      "100%": {
        backgroundColor: "transparent",
      },
    },
  };

  const mappedInfo = infoMapping[installationType] || {};

  const scrollToFields = () => {
    textFieldsRef.current.forEach((field) => {
      field.scrollIntoView({ behavior: "smooth", block: "center" });
    });
  };

  const handleTabChange = (event, newValue) => {
    if (
      newValue === 1 &&
      tempSizeL === "85.0" &&
      tempSizeH === "65.0" &&
      tempSizeP1 === "40.0" &&
      tempSizeP2 === "40.0"
    ) {
      setShowSnackbar(true); // Show Snackbar
      scrollToFields(); // Scroll to text fields
      setHighlightFields(true); // Start the highlight animation
      setTimeout(() => {
        setHighlightFields(false); // Relancer l'animation après un délai
      }, 1500);
    } else {
      setSelectedTab(newValue);
    }
    setTabChange(newValue)
  };

  const handleSnackbarClose = () => {
    setShowSnackbar(false);
  };

  const onSizeHChangeLocal = (value) => {
    if (value.match(/[^0-9.,]/)) return;
    if (value.includes(",")) value = value.replace(",", ".");
    setTempSizeH(value);
  };

  const onSizeLChangeLocal = (value) => {
    if (value.match(/[^0-9.,]/)) return;
    if (value.includes(",")) value = value.replace(",", ".");
    setTempSizeL(value);
  };

  const onSizeP1ChangeLocal = (value) => {
    if (value.match(/[^0-9.,]/)) return;
    if (value.includes(",")) value = value.replace(",", ".");
    setTempSizeP1(value);
  };

  const onSizeP2ChangeLocal = (value) => {
    if (value.match(/[^0-9.,]/)) return;
    if (value.includes(",")) value = value.replace(",", ".");
    setTempSizeP2(value);
  };

  const onSizeLBlur = () => {
    setTempSizeL((prev) => {
      let value = parseFloat(prev.replace(",", "."));
      if (isNaN(value)) {
        // Remettre la valeur par défaut si NaN
        value = (sizeL / 10).toFixed(1); // Par exemple, remettre la valeur par défaut à `sizeL / 10`
      } else {
        value = value.toFixed(1);
      }
      handleLChange(value);
      return value;
    });
  };

  const onSizeHBlur = () => {
    setTempSizeH((prev) => {
      let value = parseFloat(prev.replace(",", "."));
      if (isNaN(value)) {
        // Remettre la valeur par défaut si NaN
        value = (sizeH / 10).toFixed(1); // Par exemple, remettre la valeur par défaut à `sizeH / 10`
      } else {
        value = value.toFixed(1);
      }
      handleHChange(value);
      return value;
    });
  };

  const onSizeP1Blur = () => {
    setTempSizeP1((prev) => {
      let value = parseFloat(prev.replace(",", "."));
      if (isNaN(value)) {
        // Remettre la valeur par défaut si NaN
        value = (sizeP1 / 10).toFixed(1); // Par exemple, remettre la valeur par défaut à `sizeP1 / 10`
      } else {
        value = value.toFixed(1);
      }
      handleP1Change(value);
      return value;
    });
  };

  const onSizeP2Blur = () => {
    setTempSizeP2((prev) => {
      let value = parseFloat(prev.replace(",", "."));
      if (isNaN(value)) {
        // Remettre la valeur par défaut si NaN
        value = (sizeP2 / 10).toFixed(1); // Par exemple, remettre la valeur par défaut à `sizeP2 / 10`
      } else {
        value = value.toFixed(1);
      }
      handleP2Change(value);
      return value;
    });
  };

  const handleLChange = (value) => {
    const numericValue = parseFloat(value.replace(",", "."));
    if (!isNaN(numericValue)) {
      onSizeLChange(numericValue * 10);
    } else {
      console.error("La valeur entrée est invalide.");
    }
  };

  const handleHChange = (value) => {
    const numericValue = parseFloat(value.replace(",", "."));
    if (!isNaN(numericValue)) {
      onSizeHChange(numericValue * 10);
    } else {
      console.error("La valeur entrée est invalide.");
    }
  };

  const handleP1Change = (value) => {
    const numericValue = parseFloat(value.replace(",", "."));
    if (!isNaN(numericValue)) {
      onSizeP1Change(numericValue * 10);
    } else {
      console.error("La valeur entrée est invalide.");
    }
  };

  const handleP2Change = (value) => {
    const numericValue = parseFloat(value.replace(",", "."));
    if (!isNaN(numericValue)) {
      onSizeP2Change(numericValue * 10);
    } else {
      console.error("La valeur entrée est invalide.");
    }
  };

  return (
    <Box sx={highlightFieldsAnimation}>
      <Box
        sx={{ paddingRight: "20px", paddingLeft: "20px", textAlign: "center" }}
      >
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
        >
          <Typography
            variant="h3"
            gutterBottom
            align="center"
            sx={{
              mb: 1,
              fontSize: { xs: "1rem", sm: "1.5rem", md: "2rem", lg: "2rem" },
            }}
          >
            Mesurer mon installation
            <InfoIcon
              sx={{
                fontSize: "18px",
                color: "#007ac3",

                ml: 1,
                cursor: "pointer",
              }}
              onClick={(e) => {
                e.stopPropagation();
                handleInfoClick("title");
              }}
            />
          </Typography>
        </Box>

        {selectedInfo === "title" && (
          <Box
            ref={popupRef}
            sx={{
              position: "absolute",
              top: isXs ? "30%" : "35%",
              right: isXs ? "12.5vw" : "12%",
              mt: 1,
              width: isXs ? "75vw" : "400px",
              zIndex: 21,
              fontWeight: "300",
              padding: "20px",
              backgroundColor: "#f9f9f9",
              boxShadow: "0 0 35px rgb(0 0 0 / 19%)",
              borderRadius: "5px",
              fontFamily: "'Fira Sans',Helvetica,Arial,Lucida,sans-serif",
              fontSize: "13px",
            }}
          >
            <Typography variant="body2" color="textSecondary">
              Mesurez précisément les différentes dimensions de votre
              installation, nous vous proposerons un cache aux dimensions
              optimisées pour la ventilation et la pose.
            </Typography>
          </Box>
        )}

        {installationImages[installationType] && (
          <Typography
            variant="body1"
            gutterBottom
            sx={{
              mb: 1,
              color: "#007AC3",
              fontWeight: "bold",
              fontSize: { xs: "12px", sm: "12px", md: "15px", lg: "15px" },
            }}
          >
            {installationImages[installationType].subtitle1}
            <u>{installationImages[installationType].subtitle2}</u>
            {installationImages[installationType].subtitle3}
          </Typography>
        )}

        <Box
          sx={{
            display: "flex",
            justifyContent: "center", // Centre les éléments horizontalement
            alignItems: "center", // Aligne les éléments verticalement
            mb: 3,
            zIndex: 3,
            position: "relative",
          }}
        >
          <Snackbar
            open={showSnackbar}
            autoHideDuration={4000}
            onClose={handleSnackbarClose}
            message="Vous devez d'abord renseigner les valeurs de votre unité"
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            sx={{
              zIndex: 1200, // Ensure Snackbar is above all other UI components
              paddingBottom: { xs: "7vh", md: "5vh" }, // To avoid overlap with other UI elements like footers or fixed nav bars
            }}
          />
          <Tabs
            sx={{
              flexGrow: 1, // Les onglets prennent tout l'espace disponible
            }}
            value={selectedTab}
            onChange={handleTabChange}
            centered
            TabIndicatorProps={{
              style: {
                backgroundColor: "#007ac3",
              },
            }}
          >
            <FormTabStyled
              selected={selectedTab === 0}
              label="Dimensions de mon installation"
            />

            <FormTabStyled
              selected={selectedTab === 1}
              label={
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "8px", // Espace entre texte et icône
                  }}
                >
                  Dimensions de mon cache
                  <InfoIcon
                    sx={{
                      fontSize: "18px",
                      color: "#007ac3",
                      cursor: "pointer",
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleInfoClick("desc");
                    }}
                  />
                </Box>
              }
            />
          </Tabs>
        </Box>

        {selectedInfo === "desc" && (
          <Box
            ref={popupRef}
            sx={{
              position: "absolute",
              top: isXs ? "40%" : "30%",
              right: isXs ? "12.5vw" : "10%",
              mt: 1,
              width: isXs ? "75vw" : "400px",
              zIndex: 21,
              fontWeight: "300",
              padding: "20px",
              backgroundColor: "#f9f9f9",
              boxShadow: "0 0 35px rgb(0 0 0 / 19%)",
              borderRadius: "5px",
              fontFamily: "'Fira Sans',Helvetica,Arial,Lucida,sans-serif",
              fontSize: "13px",
            }}
          >
            <Typography variant="body2" color="textSecondary">
              Les dimensions automatiquement calculées pour votre cache
              priorisent la bonne distribution du flux d'air, une pose facilitée
              et un encombrement contenu. Vous pouvez les modifier, ou revenir
              aux dimensions conseillées à tout moment en cliquant sur «
              réinitialiser les dimensions de mon cache.
            </Typography>
          </Box>
        )}

        <Box sx={{ display: selectedTab === 0 ? "block" : "none" }}>
          {installationImages[installationType] && (
            <Box sx={{ textAlign: "center", mb: 3, mt: 3 }}>
              <Box
                component="img"
                src={installationImages[installationType].src}
                alt={installationImages[installationType].label}
                sx={{ width: "100%", maxWidth: "400px", borderRadius: 2 }}
              />
            </Box>
          )}
        </Box>

        <Box sx={{ display: selectedTab === 1 ? "block" : "none" }}>
          <CacheDimensionsForm
            handleKeyDown={handleKeyDown}
            sizeX={sizeX}
            sizeY={sizeY}
            sizeZ={sizeZ}
            frontHeight={frontHeight}
            onSizeXChange={onSizeXChange}
            onRearYChange={onRearYChange}
            onSizeZChange={onSizeZChange}
            errorString={dimensionsErrorString}
            addContactUs={dimensionsNeedDevis}
            isError={isDimensionsError}
            P1={sizeP1}
            P2={sizeP2}
            H={sizeH}
            L={sizeL}
            installationType={installationType}
            bottom={bottom}
          />
        </Box>

        {dimensionsErrorString && (
          <Box sx={{ width: "100%", padding: "10px 20px 20px" }}>
            <InfoNotice
              content={dimensionsErrorString}
              padding="10px 20px"
              addContactUs={dimensionsNeedDevis}
              fontWeight="900"
              isError={isDimensionsError}
            />
          </Box>
        )}

        {selectedTab === 0 && (
          <Grid2
            container
            spacing={2}
            justifyContent="center"
            alignItems="center"
          >
            <Grid2 size={{ xs: 6, sm: 3 }}>
              {renderTextFieldWithInfo(
                "Largeur L",
                tempSizeL,
                onSizeLChangeLocal,
                onSizeLBlur,
                mappedInfo.width,
                "42.5vw"
              )}
            </Grid2>
            <Grid2 size={{ xs: 6, sm: 3 }}>
              {renderTextFieldWithInfo(
                "Hauteur H",
                tempSizeH,
                onSizeHChangeLocal,
                onSizeHBlur,
                mappedInfo.height,
                "-2vw"
              )}
            </Grid2>
            <Grid2 size={{ xs: 6, sm: 3 }}>
              {renderTextFieldWithInfo(
                "Profondeur P1",
                tempSizeP1,
                onSizeP1ChangeLocal,
                onSizeP1Blur,
                mappedInfo.p1,
                "42.5vw"
              )}
            </Grid2>
            <Grid2 size={{ xs: 6, sm: 3 }}>
              {renderTextFieldWithInfo(
                "Profondeur P2",
                tempSizeP2,
                onSizeP2ChangeLocal,
                onSizeP2Blur,
                mappedInfo.p2,
                "-2vw"
              )}
            </Grid2>
          </Grid2>
        )}
      </Box>
    </Box>
  );
};

export default DimensionsForm;
